import React from "react";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import CategoryBanner from "../CategoryBanner/CategoryBanner";

const SocialCMMarketplace = () => {
  const userType = ["FOOD-PROCESSOR"];
  let navData = [
    { to: "/b2b-onmarketplace", link: "For B2B Buyers", }, { to: "/b2b-onmarketplace-food-processing-enterprise", link: "For Food Processing Enterprise" }
  ];
  const categoryImage={}
  return (
    <>
    {/* <HeaderComponent/> */}
      {/* <SecondaryNavbar props={navData} /> */}
      <CategoryBanner MarketCategoryType="AGR15" categoryImage={categoryImage} quote="quote" services="true"/>
      <FooterComponent/>

    </>
  );
};

export default SocialCMMarketplace;
