import React, { useState } from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import CTA from "../CTA/CTA";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import "./ForManufacturers.css";
import ManufacturersBenefits from "./ManufacturersBenefits";
import ManufacturesForm from "./ManufacturesForm";
import TitleDescription from "../TitleDescription/TitleDescription";
import { Container } from "@material-ui/core";
import ManufacturesRoadMap from "./ManufacturesRoadMap";
import ManufacturerServices from "./ManufacturerServices";
import BrandCTA from "../BrandCTA/BrandCTA";
import MSMECoustomerBenefit from "../WebSite/MSMECoustomerBenefit/MSMECoustomerBenefit";
import BrandChallenges from "./BrandChallenges";
import SolutionEcosystem from "../WebSite/SolutionEcosystem/SolutionEcosystem";
import CollectionProduct from "../CollectionProduct/CollectionProduct";
import AgriEntreprenurCertifications from "../ForAgriEntreprenuer/AgriEntreprenurCertifications";
import CMProcess from "./CMProcess";
import CMProcessCards from "./CMProcessCards";
export default function ForManufactures() {
  return (
    <>
      {/* <HeaderComponent /> */}
      <UpdatesBanner
        className="forManufactures"
        bannerText={[
          "We Build Brands and help the existing one to Package",
          <br className="d-lg-block d-none"></br>,
          "Organic and Traceable Products",
        ]}
        buttonText = "Start Now"
        href="/social-cm-marketplace"
      />
      {/* <div className="main-wrapper2">
        <BrandChallenges />
      </div>
      <Container>
        <SolutionEcosystem
          type="Brands"
          bodycopy="Gbr farming provides an ecosystem for Agri-MSMEs, Brands, agrifood investors and merchant exporters"
        />
  
 
      {/* <div className="for-manufacturers-wrapper"> */}

      {/* <ManufacturesRoadMap /> */}
      {/* </div> */}
      <div className="">
        <Container>
          <ManufacturerServices />
        </Container>
      </div>
<div className="main-wrapper2">
      <Container>
        <CollectionProduct title = "Our Categories"/>
      </Container>
      </div>
      <div className="">
        <Container>
        <AgriEntreprenurCertifications />
        </Container>
      </div> 
      <div className="main-wrapper2 subSectionWrapper desktop-vis">

      <CMProcess/>
      </div>

      <div className="main-wrapper2 subSectionWrapper desktop-hide">

<CMProcessCards/>
</div>
      {/* <Container>

      <CMProcessCards/>
      </Container> */}

      <Container className="mt-4">
        <MSMECoustomerBenefit />
      </Container>
      {/* <BrandCTA /> */}
      <FooterComponent />
    </>
  );
}
