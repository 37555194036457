import React from "react";
import Toast from "light-toast";
import { Container } from "@material-ui/core";
import "react-awesome-slider/dist/styles.css";
import HeaderComponent from "../../WebSite/Header/HeaderComponent";
import FooterComponent from "../../WebSite/Footer/FooterComponent";
import "../App.css";
import SolutionEcosystem from "../SolutionEcosystem/SolutionEcosystem";

import UpdatesBanner from "../../UpdatesBanner/UpdatesBanner";
import CTA from "../../CTA/CTA";

import ForFarmerBenefits from "../ForFarmerBenefits/ForFarmerBenefits";
import ForFarmerChallenges from "../ForFarmerChallenges/ForFarmerChallenges";
import ForInputProviderChallenges from "../ForInputProviderChallenges/ForInputProviderChallenges";
import InputProviderBenefits from "../InputProviderBenefits/InputProviderBenefits";
import GBRFarmerService from "../../AgriProducers/Farmer/GBRFarmerService";
export default function ForInputProviders() {
  return (
    <>
    {/* <HeaderComponent /> */}
    <UpdatesBanner
    
      className="farmUpdateBanner"
      bannerTitle ="Input Manufacturers"
      bannerbodycopy1={[
"We help Input Manufacturers to expand their markets"          ]}
      // bannerbodycopy="Brand Recognization | Increased Income | Job Creations | Market Access | Value Addition"
      // buttonText2="Sign in"
      // href2="http://abn.gbrfarming.com/"
      buttonText2="Sign up"
      href2="http://abn.gbrfarming.com/auth/3/signup"
    />
    <div className="main-wrapper2">
      <ForInputProviderChallenges/>
    </div>
   
    <SolutionEcosystem type = "IP" bodycopy = "System and Process for Input Providers to join CBN Network/Club of his own choice to do the hyper-local trade with CBO and Agro-Dealers."/>
    <div className="main-wrapper2">

    <InputProviderBenefits/>
    </div>

    <GBRFarmerService/>


    <Container>
      {" "}
      {/* <AgriEntrepenurBenefits /> */}
      <div className="subSectionWrapper">
      <CTA
      // secondBtn= "true" 
      // href3="/agri-business"
      // btnText2= "Explore Agri Business"
       data="present"
        href2="http://abn.gbrfarming.com/auth/3/signup"
        heading="Empowering Input-Manufacturers to Reach New Heights with GBR Care's Advertisement Excellence!"
        btnText="Join Now"
      />
    </div>
    </Container>
   
    <FooterComponent />
  </>
  )
}
