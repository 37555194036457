import { Container } from "@material-ui/core";
import React from "react";
import ServiceTypes from "../ServiceTypes/ServiceTypes";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import "./IAA.css";
import IAABanner from "./IAABanner";
import LocalTradeImg from "../../images/agreement.png";
import GlobalTradeImg from "../../images/statistics.png";
import InvestementImg from "../../images/investment.png";

export default function IAA() {
  return (
    <>
      {/* <HeaderComponent /> */}
      <IAABanner />
      <Container className="IAAContainer">
        <ServiceTypes
          id="GlobalTrade"
          title="Global Trade"
          src={GlobalTradeImg}
          description1="Explore Global trade through Global Trade Netwotk"
          href="/onMarketplace"
          buttonCnt="Explore Global Trade"
        />

        <ServiceTypes
          id="ExploreInvestment"
          title=" Investment"
          src={InvestementImg}
          description1="Explore Investment in Agri assets"
          href="/ExploreInvestment"
          buttonCnt="Explore Investement"
        />
        <ServiceTypes
          id="LocalTrade"
          title="Local Trade "
          src={LocalTradeImg}
          description1="Explore Local trade through CBN"
          href="/ForProducers"
          buttonCnt="Explore Local Trade"
        />
      </Container>
      <FooterComponent />
    </>
  );
}
