import React from 'react';
import FooterComponent from '../WebSite/Footer/FooterComponent';
import HeaderComponent from '../WebSite/Header/HeaderComponent';
import CommingSoon from "../../images/coming-soon.png";
import { Container } from '@material-ui/core';
import "./Request.css";

export default function Request() {
  return (
    <>
    {/* <HeaderComponent/> */}
    <Container className='request-container '>
        <img src={CommingSoon} alt="Comming Soon Image"/>
    </Container>
    <FooterComponent/>
    </>
  )
}
