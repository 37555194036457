import React from "react";
import "./ManufacturingOnDemand.css";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import AboutSubSection from "../aboutSubSection/AboutSubSection";
import ContractFarmingImg from "../../images/white-label1.jpg";
import PrivateLabelling from "../../images/brand-images/private-labelling.jpg";
import NewDevelopment from "../../images/brand-images/skills-concept-skills-education-learning-personal-development-competency-business-min.jpg";
// import BrandCTA from "../BrandCTA/BrandCTA";
export default function ManufacturingOnDemand(){
    return(
        <>
        {/* <HeaderComponent/> */}
        <UpdatesBanner 
         className="MoDBanner text-white"
         bannerText="Manufacturing on Demand for Brands"
        //  buttonText="Sign up"
        //  href="http://localhost:3001/auth/undefined/signup"
        /> 
      <div className="for-manufacturers-wrapper">
        <AboutSubSection
          className="rowReverseAbout"
          description="AgriProcessingHub specializes in providing contract manufacturing services, catering to diverse product needs. With a proven track record of expertise and reliability, We collaborate closely with brands to bring their concepts to life. From formulation to production and packaging, AgriProcessingHub ensures a seamless manufacturing process, delivering high-quality products that meet client specifications."
          title="Contract Manufacturing"
          src={ContractFarmingImg}
        />
        <AboutSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod"
          description="AgriProcessingHub excels in private labeling services, allowing businesses to brand high-quality products as their own. Through this service, brands can leverage AgriProcessingHub's expertise to bring their unique products to market with a personalized touch.
          "
          title="Private Labelling"
          src={PrivateLabelling}
        />
        {/* <AboutSubSection
          className="rowReverseAbout"
          description="AgriProcessingHub serves as a reliable alternate raw material supplier, offering businesses a stable sourcing solution. With a commitment to quality, we provide a range of materials to meet varying production needs. brands benefit from AgriProcessingHub's expertise in maintaining a consistent supply of materials."
          title="Alternate Raw Material Supply"
          src={Supplier}
        /> */}
        <AboutSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout"
          description="AgriProcessingHub offers new product development services tailored to brands, helping them innovate and diversify their product offerings to meet evolving market demands."
          title="New Product Development"
          src={NewDevelopment}
        />
        {/* <BrandCTA/> */}
        {/* <AboutSubSection
          className="rowReverseAbout"
          description="AgriProcessingHub provides brands with the service of expanding their product sales into both local and global marketplaces, leveraging their expertise for effective market penetration.
          "
          title="onMarketplace"
          src={Market}
        /> */}
</div>

        <FooterComponent/>
        </>
    )
}