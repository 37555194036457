import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import HomeSubSection from "../WebSite/HomeSubSection/HomeSubSection";
// import "./CareServices.css";

export default function AssetManagementServices() {
  return (
    <>
      {/* <HeaderComponent /> */}
      <UpdatesBanner bannerText="Asset Management & Facilitation Service" className="asset-management-service-bg" />
      <div className="main-wrapper2">
        <div className="subSectionWrapper">
          <HomeSubSection
            className3="advisory-service"
            className1="aboutBgGrey"
            className="rowReverseAbout--mod borderedCards"
            descriptionTitle="For Investors"
            description="AgriProcessingHub offers a comprehensive asset management service tailored for investors interested in the agricultural sector. Through this service, investors can allocate funds to various agriculture-related assets, including processing machinery. AgriProcessingHub manages these assets through processing entrepreneur, leveraging their expertise to maximize returns and ensure sustainable practices. This provides investors with a unique opportunity to benefit from the agricultural industry's growth while contributing to the development of essential agricultural infrastructure."

          />

       
          <HomeSubSection
            className1="aboutBgGrey"
            className="rowReverseAbout borderedCards borderedCards"
            className3="fieldSupportService2"
            descriptionTitle="For Processing Entrepreneur 
            "
            description="AgriProcessingHub facilitates processing entrepreneur  with processing machinery. AgriProcessingHub aids processing entrepreneur  in production of the processing food and improve the topline. With a focus on quality management, logistics, and coordination, AgriProcessingHub enables processing entrepreneur to optimize resources and enhance productivity while ensuring high-quality outputs for various brands."
            
          />
         
        </div>
      </div>
      <FooterComponent />
    </>
  );
}
