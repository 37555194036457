import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import "./AssetService.css";
import IAATab from "../WebSite/IAATab/IAATab";
import HomeSubSection from "../WebSite/HomeSubSection/HomeSubSection";
import CTA from "../CTA/CTA";
import { Container } from "@material-ui/core";
import CollectionProduct from "../CollectionProduct/CollectionProduct";


const processingCapacity = (
  <>
  <HomeSubSection
    className="rowReverseAbout borderedCards borderedCards--mod"
    className3="processingSupport3"
    description="AgriProcessingHub's Monetize Processing Capacity service empowers processing entrepreneurs to optimize their equipments and operations, producing top-quality goods for both local and global brands."
    descriptionTitle="Monetize Processing Capacity"
  />

  <HomeSubSection
  className="rowReverseAbout--mod borderedCards borderedCards--mod"
  className3="processingSupport6"
  description="Store Request is an income-generating service provided by AgriProcessingHub for food processing entrepreneurs, allowing them to offer their available storage space to others. This service enables entrepreneurs to monetize unused storage capacity by renting it out to fellow businesses in need of storage solutions. By facilitating this exchange, AgriProcessingHub helps entrepreneurs optimize their resources and generate additional revenue streams."
  descriptionTitle="Store Request"
/>
</>
);





const AssetService = () => {



  return (
    <>
      {/* <HeaderComponent /> */}
      <UpdatesBanner className="processing" bannerText="Monetize the processing & packaging capacity" />
      <div className="subSectionWrapper pb-0">
        <h2 className="container-title text-center">Income-generating Services</h2>
        { processingCapacity}
        </div>




        <Container className="subSectionWrapper">
        <CTA
            onClick={() => {
              localStorage.setItem("userType", "Production-Manager");
            }}
            href="/auth/3/signup"
            heading= "Want to become an processing entrepreneurs to do processing  and packaging for 3rd parties  to monetize unutilized capacity"
            btnText="Register Now"
          
          />
        </Container>
      <FooterComponent />
    </>
  );
};

export default AssetService;
