import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import HomeSubSection from "../WebSite/HomeSubSection/HomeSubSection";
// import "./CareServices.css";

export default function ContractManufacturingService() {
  return (
    <>
      {/* <HeaderComponent /> */}
      <UpdatesBanner bannerText="Contract Services" className="contract-farming-service-bg" />
      <div className="main-wrapper2">
        <div className="subSectionWrapper">
          <HomeSubSection
            className3="advisory-service"
            className1="aboutBgGrey"
            className="rowReverseAbout--mod borderedCards"
            descriptionTitle="For Brands"
            description="AgriProcessingHub offers comprehensive contract services to brands, encompassing contract manufacturing.AgriProcessingHub's contract manufacturing service focuses on the processing and production of agricultural products, providing tailored solutions to meet the specific needs of their clients. Together, these services enable AgriProcessingHub to deliver high-quality agricultural products, supporting brands in achieving their production goals."
        
          />

        
             <HomeSubSection
            className="rowReverseAbout borderedCards borderedCards"
            className3="contract-manufacturing"
            description="   AgriProcessingHub provides back to back processing and packaging jobs to approved processing entrepreneurs with all the raw material, allowing them to monetize their unutilized manufacturing capacity. Through this service, processing entrepreneur  receive specific production tasks and standards to meet, ensuring a steady stream of work and income. AgriProcessingHub manages the logistics and coordination, enabling processing entrepreneur to optimize their resources and enhance productivity while maintaining high-quality outputs for various brands."         
          
            descriptionTitle="For Processing Entrepreneur "
          />
        </div>
      </div>
      <FooterComponent />
    </>
  );
}
