import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import prod2 from "../../../images/substance.png";
import { Container } from "react-bootstrap";
import HeaderComponent from "../../WebSite/Header/HeaderComponent";
import FooterComponent from "../../WebSite/Footer/FooterComponent";
import ExoploreProductBanner from "../../ExploreProductBanner/ExoploreProductBanner";
import SecondaryNavbar from "../../SecondaryNavbar/SecondaryNavbar";
import { NavHashLink } from "react-router-hash-link";
import ProcessedProducts from "../ProcessedProducts";
import CategoryBanner from "../../CategoryBanner/CategoryBanner";

const BulkonMarketplace = () => {
  

  const [rawProduct, setRawProduct] = useState(true);
  const [semiProduct, setSemiProduct] = useState(false);
  const [processedProduct, setProcessedProduct] = useState(false);
  const [userType, setUserType] = useState(["FOOD-PROCESSOR"]);

  const location = useLocation();
  const history = useHistory();
  const customPropValue = location.state?.customProp;

  let navData = [
    // { to: "/onMarketplace", link: "For B2C Buyers",  },
    { to: "/bulk-onmarketplace-sell", link: "For Farm Enterprise" },
    { to: "/bulk-onmarketplace", link: "For B2B Buyers" },

    // { to: "/onMarketplaceRFQ", link: "RFQ" }
  ];

  // Conditionally include customPropValue in navData
  if (customPropValue) {
    navData = [
      { to: "/onMarketplace", link: "For B2C Buyers" },
      { to: "/onMarketplace-b2b-buyers", link: "For B2B Buyers" },

      { to: "/onMarketplaceSell", link: "For Sellers", customPropValue },
      // { to: "/onMarketplaceRFQ", link: "RFQ" }
    ];
  }
  const rawProductHandler = () => {
    setRawProduct(true);
    // history.push({
    //   pathname: "/onMarketplace",
    //   hash: "#Raw-Products",
    //   state: { customProp: customPropValue },
    // });
  };

  const semiProductHandler = () => {
    setSemiProduct(true);
    setRawProduct(false);
    setProcessedProduct(false);
    // history.push({
    //   pathname: "/onMarketplace",
    //   hash: "#Semi-Processed-Products",
    //   state: { customProp: customPropValue },
    // });
  };

  const processedProductHandler = () => {
    setProcessedProduct(true);
    setRawProduct(false);
    setSemiProduct(false);
    // history.push({
    //   pathname: "/onMarketplace",
    //   hash: "#Processed-Products",
    //   state: { customProp: customPropValue },
    // });
  };
const categoryImage = {}
  return (
    <>
      {/* <HeaderComponent className="noShadow" /> */}
      {/* <SecondaryNavbar props={navData} /> */}
  <CategoryBanner bannerMessage={true} MarketCategoryType="AGR11" categoryImage={categoryImage} quote="quote"/>
      <FooterComponent />
    </>
  );
};

export default BulkonMarketplace;
