import React from "react";
import HomeSubSection from "../WebSite/HomeSubSection/HomeSubSection";
import AdvertisementCBN from "../gbrEngage/Advertisement/AdvertisementCBN";
import { Row, Col } from "react-bootstrap";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
 
export default function AdvertisementService() {
 
 
  return (
    <>
{/* <HeaderComponent/> */}
<UpdatesBanner
        className="Advertisement-bg"
        bannerText={[
          "Advertisement"
        ]}
      />
    <div className="subSectionWrapper ">
             {/* <h3 class="container-title text-center">Advertisements</h3> */}
 
 
<HomeSubSection
  className1="aboutBgGrey"
  className="rowReverseAbout--mod borderedCards borderedCards--mod"
  className3="image19"
  descriptionTitle="
  Advertisements"
  description="
  GBR Care offers a comprehensive Advertisement service tailored specifically for Input-manufacturers. Our expert team works closely with input manufacturers to create compelling and effective advertisements that resonate with their target audience. Whether it's through captivating visuals, persuasive messaging, or strategic placement, we ensure that your advertisements leave a lasting impact and drive results. With GBR Care, input manufacturers can effectively promote their products and reach their marketing goals with confidence.  "
 
  userType="Farmers-Machinery-Provider"
/>
{/* <AdvertisementCBN /> */}
    </div>
    <FooterComponent/>
    </>
 
  );
}
 
