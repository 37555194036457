import React from "react";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import { Container } from "@material-ui/core";
import ProcessedProducts from "./ProcessedProducts";
import SecondaryNavbar from "../SecondaryNavbar/SecondaryNavbar";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import CategoryBanner from "../CategoryBanner/CategoryBanner";

const D2ConMarketplace = () => {
  const userType = ["FOOD-PROCESSOR"];
  let navData = [
    { to: "/d2c-onmarketplace", link: "For Consumers" },
    {
      to: "/d2c-onmarketplace-food-processing-enterprise",
      link: "For Food Processing Enterprise",
    },
    // { to: "/onMarketplaceRFQ", link: "RFQ" }
  ];

  const categoryImage = {}

  return (
    <>
    {/* <HeaderComponent/> */}
      {/* <SecondaryNavbar props={navData} /> */}

  <CategoryBanner categoryImage={categoryImage} MarketCategoryType="6a44e2fa-8fce-4108-8039-528c84173e56"/> 
      <FooterComponent/>
    </>
  );
};

export default D2ConMarketplace;
