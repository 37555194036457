import React from 'react';
import ClubMainPage from '../Club/ClubMainPage';
import "./RFQ.css"
import HeaderComponent from '../WebSite/Header/HeaderComponent';
import FooterComponent from '../WebSite/Footer/FooterComponent';
import SecondaryNavbar from '../SecondaryNavbar/SecondaryNavbar';

    
    
    
const userTypes= ["FOOD-PROCESSOR", "AssetDeveloper"]

export default function RFQ() {
  const navData = [{to:"/RFQ", link:"RFQ"},
  {to:"/Tender", link:"Tender"},
  {to:"/ContractFarming", link:"Contract Farming"},
  {to:"/ContractManufacturing", link:"Contract Manufacturing"},
  {to:"/TimelyDelivery", link:"Timely Delivery"}
]


  return (
    <>
    {/* <HeaderComponent className="noShadow"/> */}
    <SecondaryNavbar props={navData} />
    <div className='subSectionWrapper'>
    <ClubMainPage type={userTypes}/>
    </div>
    <FooterComponent/>
    </>
  )
}
