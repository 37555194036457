import React from "react";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import { Container } from "@material-ui/core";
import ProcessedProducts from "./ProcessedProducts";
import SecondaryNavbar from "../SecondaryNavbar/SecondaryNavbar";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import CategoryBanner from "../CategoryBanner/CategoryBanner";

const B2BonMarketplace = () => {
  const userType = ["FOOD-PROCESSOR"];
  let navData = [
    { to: "/b2b-onmarketplace", link: "For B2B Buyers", }, { to: "/b2b-onmarketplace-food-processing-enterprise", link: "For Food Processing Enterprise" }
  ];
  const categoryImage={}
  return (
    <>
    {/* <HeaderComponent/> */}
      {/* <SecondaryNavbar props={navData} /> */}
      <CategoryBanner MarketCategoryType="PRO11" categoryImage={categoryImage} quote="quote"/>
      <FooterComponent/>

    </>
  );
};

export default B2BonMarketplace;
