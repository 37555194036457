import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import prod1 from "../../images/cool.png";
import prod2 from "../../images/substance.png";
import prod4 from "../../images/dry.png";
import {Container } from "react-bootstrap";
// import "./ExploreProducts.css";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import ExoploreProductBanner from "../ExploreProductBanner/ExoploreProductBanner";

import ProcessedProducts from "../ExploreProducts/ProcessedProducts";
import SecondaryNavbar from "../SecondaryNavbar/SecondaryNavbar";

const ExploreCareProducts = () => {
   
  const navData = [{to:"/onMarketplace", link:"Buy"},
  {to:"/onMarketplaceSell", link:"Sell"},
  {to:"/onMarketplaceRFQ", link:"RFQ"}
]
  const[rawProduct,setRawProduct] = useState(true);
  const[semiProduct,setSemiProduct] = useState(false);
  const[proccessedProduct,setproccessedproduct] = useState(false);
  const [userType, setUserType] = useState(["FOOD-PROCESSOR"])

  const rawProductHandler =() =>{
    setRawProduct(true);
    setSemiProduct(false);
    setproccessedproduct(false);
  }

  const semiProductHandler =() =>{
    setSemiProduct(true);
    setRawProduct(false);
    setproccessedproduct(false);
  }
  const processedProductHandler =() =>{
    setproccessedproduct(true);
    setRawProduct(false);
    setSemiProduct(false);
  }

  return (
    <>
      {/* <HeaderComponent  /> */}
      <div>
        <div className="serviceContainer ExploreBannerContainer" id="ExploreBannerContainer">
          <div className="gridSection">
            <h1 className="container-title mb-2 text-white">
              Ensured Quality | Tracebility | Provenance
            </h1>
            {/* <p className="container-para text-white">
              Ensure food saftey across globe
            </p> */}

            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={2}
              direction="row"
            >
              <Grid
                id="complementaryServices_link"
                className="hoverEffect"
                item
                md={4}
                sm={4}
                xs={6}
              >
                <a onClick={rawProductHandler} href="#Raw-Products">
                  <img src={prod2} alt="" />
                  <h4>
                    Animal Care <br className="d-none d-md-block"></br>Products
                  </h4>
                </a>
              </Grid>

              <Grid
                id="complementaryServices_link"
                className="hoverEffect"
                item
                md={4}
                sm={4}
                xs={6}
              >
                <a onClick={semiProductHandler} href="#Semi-Processed-Products">
                  <img src={prod4} alt="" />
                  <h4>
                    Plant Care <br className="d-none d-md-block"></br>{" "}
                    Products
                  </h4>
                </a>
              </Grid>
   
            </Grid>
          </div>
        </div>
      </div>
      <div className="explore-product-wrapper">
        <Container>
          <div className=" ">
            {/* Raw-Products */}
          {rawProduct === true &&  <div id="tendersContainer" className="container-fluid">
              <h3 id="Raw-Products" className="container-title  ">
                Animal Care Products
              </h3>
              <ProcessedProducts type="AHP" userType={userType} cbn="true" />
            </div>}
            {/* Semi-Processed-Products */}
            {semiProduct ===true && <div id="tendersContainer" className="container-fluid">
              <h3 id="Semi-Processed-Products" className="container-title">
                {" "}
                Plant Care Products
              </h3>
              <ProcessedProducts type="PHP" userType={userType} cbn="true" />
            </div>}
            {/* Processed-Products */}
           
          </div>
        </Container>
      </div>
      <FooterComponent />
    </>
  );
};

export default ExploreCareProducts;
