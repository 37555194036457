import React from "react";
import "./POServices.css";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import AboutSubSection from "../aboutSubSection/AboutSubSection";
import ContractFarmingImg from "../../images/Po/traceability.jpg";
import PrivateLabelling from "../../images/Po/status.jpg";

import BrandCTA from "../BrandCTA/BrandCTA";
export default function QualityAssurance(){
    return(
        <>
        {/* <HeaderComponent/> */}
        <UpdatesBanner 
         className="QualityAssuranceBanner text-white"
         bannerText="Quality Assurance"
        //  buttonText="Sign up"
        //  href="http://localhost:3001/auth/undefined/signup"
        /> 
      <div className="for-manufacturers-wrapper">
        <AboutSubSection
          className="rowReverseAbout"
          description="
          The quality assurance process for each processing entrepreneur is a meticulously vetted system designed to ensure adherence to rigorous quality standards for every production run. This process begins with a comprehensive assessment of the entrepreneur's production facilities and protocols. Each step of the production cycle is scrutinized, from raw material sourcing to final product packaging.
           "
          title="Quality Assurance"
          src={ContractFarmingImg}
        />
        {/* <AboutSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod"
          description="AgriProcessingHub offers Farm Process Traceability as a service to brands, providing comprehensive oversight of agricultural practices from seed to harvest. Through meticulous tracking and documentation, AgriProcessingHub ensures transparency and accountability in farming operations, enabling brands to verify the integrity of their supply chain, uphold quality standards, and build consumer trust by offering insights into the origin and cultivation practices of their products.
          "
          title="Farm Process Traceability"
          src={PrivateLabelling}
        /> */}
        {/* <AboutSubSection
          className="rowReverseAbout"
          description="AgriProcessingHub serves as a reliable alternate raw material supplier, offering businesses a stable sourcing solution. With a commitment to quality, we provide a range of materials to meet varying production needs. brands benefit from AgriProcessingHub's expertise in maintaining a consistent supply of materials."
          title="Alternate Raw Material Supply"
          src={Supplier}
        /> */}
    
        {/* <BrandCTA/> */}
        {/* <AboutSubSection
          className="rowReverseAbout"
          description="AgriProcessingHub provides brands with the service of expanding their product sales into both local and global marketplaces, leveraging their expertise for effective market penetration.
          "
          title="onMarketplace"
          src={Market}
        /> */}
</div>

        <FooterComponent/>
        </>
    )
}