import React from 'react';
import UpdatesBanner from '../UpdatesBanner/UpdatesBanner';
import HeaderComponent from '../WebSite/Header/HeaderComponent';
import FooterComponent from '../WebSite/Footer/FooterComponent';
import InvestorTabs from '../WebSite/InvestorTabs/InvestorTabs';
import "./AssetForInvestment.css"
export default function AssetForInvestment() {
  return (
    <>
    {/* <HeaderComponent/> */}
        <UpdatesBanner 
         className="InvestmentAssetBanner text-white"
         bannerText="Assets for investments"
        //  buttonText="Sign up"
        //  href="http://localhost:3001/auth/undefined/signup"
        /> 

<div className='subSectionWrapper'>
    <InvestorTabs/>
    </div>
    
            <FooterComponent/>
    </>
  )
}
